import { useState } from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
import sembraSchoolService from "../../../../services/sembraSchoolService";

const ChangeUserGroupForm = ({ handleFormikSubmit, handleUserMessage }) => {
  const [agencyGroups, setAgencyGroups] = useState([]);
  const validationSchema = object().shape({
    username: string().required("Username is required"),
    userGroup: string().required("Select a user group"),
  });

  const handleValidatations = (values) => {
    const errors = {};
    if (
      values.userGroup === "Select Agency Group" ||
      values.userGroup === "No Agency Information"
    ) {
      errors.userGroup = "Select a user group";
    }
    return errors;
  };

  const handleAgencyGroups = async (username) => {
    try {
      const { data } = await sembraSchoolService.pullAgencyGroups(username);
      if (data.length > 0 && username.length >= 0) {
        setAgencyGroups(data[0].agencyGroups);
      } else {
        setAgencyGroups([]);
      }
    } catch (e) {
      handleUserMessage(
        "danger",
        `ERROR: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
      setAgencyGroups([]);
    }
  };

  return (
    <section>
      <Formik
        initialValues={{ username: "", userGroup: "" }}
        validationSchema={validationSchema}
        validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log(
              "Values:",
              values,
              "\n",
              "ERRORS",
              errors,
              "\n",
              "IS SUBMITTING:",
              isSubmitting
            )} */}

            <Form.Group className="mb-3 text-start" controlId="agencyCode">
              <Form.Label>SembraSchool User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex: hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    handleAgencyGroups(e.target.value);
                  }}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>User Group</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <GrGroup />
                </InputGroup.Text>
                <Form.Select
                  aria-label="UserGroup"
                  name="userGroup"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage(null);
                  }}
                  className={
                    errors.userGroup && touched.userGroup ? "input-error" : null
                  }
                >
                  {agencyGroups.length > 0 ? (
                    <>
                      <option>Select Agency Group</option>
                      {agencyGroups.length > 0 &&
                        agencyGroups.map((group) => (
                          <option key={group.groupID} value={group.groupID}>
                            {group.groupID}
                          </option>
                        ))}
                    </>
                  ) : (
                    <>
                      <option>No Agency Information</option>
                      {/* <option>Testing</option> */}
                    </>
                  )}
                </Form.Select>
                {errors.userGroup && touched.userGroup && (
                  <InputGroup.Text
                    className={errors.userGroup ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.userGroup && touched.userGroup ? (
                <Alert variant="danger">{errors.userGroup}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn isSubmitting={isSubmitting} text={"Update User Group"} />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ChangeUserGroupForm;
