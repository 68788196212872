import React, { useEffect, useState } from "react";
import LoginForm from "../../forms/publicRouteForms/loginForm";
import { Link, useNavigate } from "react-router-dom";
// import userService from "../../services/userService";
import userService from "../../../services/userService";
import UserMessage from "../../common/userMessages";

export default function Login() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  useEffect(() => {}, []);

  const navigate = useNavigate();

  const handleFormikSubmit = async (values, actions) => {
    try {
      userService.login(values.email, values.password).then((res) => {
        if (res.data && res.data.error) {
          handleUserMessage(
            "danger",
            `Error: ${res.data.message}`,
            "If this continues, contact your systems administrator"
          );
        } else if (!res) {
          handleUserMessage(
            "danger",
            `Error: ${res.data.message}`,
            "If this continues, contact your systems administrator"
          );
        } else {
          // navigate("../welcome");
          navigate("/sembra-success-portal/sembraTime");
        }
      });
    } catch (ex) {}
  };

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Login</h2>
        <section className="main-section-content">
          <LoginForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              <p>Trouble logging in?</p>
              <Link to="/reset-password">Reset Password</Link>
            </div>
            <div className="d-flex justify-content-around mt-3">
              <p>Forgot your username?</p>
              <p>Contact your system administrator</p>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
