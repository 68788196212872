import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { MdOutlineNumbers, MdDateRange } from "react-icons/md";

const SearchSembratimeShiftForm = (props) => {
  const validationSchema = object().shape({
    username: string().required("Username is required"),
    cliIVRID: string().required("IVRID is required"),
    shiftDate: string().required("Enter a shift date"),
  });

  // const handleValidatations = (values) => {

  // };

  const resetMessages = (inputValue) => {
    props.handleUserMessage(null);
  };

  return (
    <section>
      <Formik
        initialValues={{ username: "", cliIVRID: "", shiftDate: "" }}
        validationSchema={validationSchema}
        // validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) =>
          props.handleFormikSubmit(values, actions)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log("Values:", values)} */}
            <Form.Group className="mb-3 text-start" controlId="username">
              <Form.Label>SembraTime User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex: hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="cliIVRID">
              <Form.Label>Client IVRID</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineNumbers />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example: 100213"
                  name="cliIVRID"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.cliIVRID}
                  className={
                    errors.cliIVRID && touched.cliIVRID ? "input-error" : null
                  }
                />
                {errors.cliIVRID && touched.cliIVRID && (
                  <InputGroup.Text
                    className={errors.cliIVRID ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.cliIVRID && touched.cliIVRID ? (
                <Alert variant="danger">{errors.cliIVRID}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="shfitDate">
              <Form.Label>Shift Date</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdDateRange />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Select Shift Date"
                  name="shiftDate"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.shiftDate}
                  className={
                    errors.shiftDate && touched.shiftDate ? "input-error" : null
                  }
                />
                {errors.shiftDate && touched.shiftDate && (
                  <InputGroup.Text
                    className={errors.shiftDate ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.shiftDate && touched.shiftDate ? (
                <Alert variant="danger">{errors.shiftDate}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn isSubmitting={isSubmitting} text={"Search Shift"} />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SearchSembratimeShiftForm;
