import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import {
  MdOutlineNumbers,
  MdDateRange,
  MdOutlineLocationOn,
} from "react-icons/md";

const ChangeCheckinLocationForm = ({
  handleFormikSubmit,
  handleUserMessage,
}) => {
  const validationSchema = object().shape({
    username: string().required("Username is required"),
    cliIVRID: string().required("IVRID is required"),
    shiftDate: string().required("Select a shift date"),
    // address: string().required("Address is required"),
    // city: string().required("City is required"),
    // state: string().required("State is required"),
    lattitude: string().required("Lattitude is required"),
    longitude: string().required("Longitude is required"),
  });

  return (
    <section>
      <Formik
        initialValues={{
          username: "",
          cliIVRID: "",
          shiftDate: "",
          address: "",
          city: "",
          state: "",
          lattitude: "",
          longitude: "",
        }}
        validationSchema={validationSchema}
        // validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log("Values:", values)} */}
            <Form.Group className="mb-3 text-start" controlId="username">
              <Form.Label>SembraTime User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex: hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="cliIVRID">
              <Form.Label>Client IVRID</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineNumbers />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example: 100213"
                  name="cliIVRID"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.cliIVRID}
                  className={
                    errors.cliIVRID && touched.cliIVRID ? "input-error" : null
                  }
                />
                {errors.cliIVRID && touched.cliIVRID && (
                  <InputGroup.Text
                    className={errors.cliIVRID ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.cliIVRID && touched.cliIVRID ? (
                <Alert variant="danger">{errors.cliIVRID}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="shfitDate">
              <Form.Label>Shift Date</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdDateRange />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Select Shift Date"
                  name="shiftDate"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.shiftDate}
                  className={
                    errors.shiftDate && touched.shiftDate ? "input-error" : null
                  }
                />
                {errors.shiftDate && touched.shiftDate && (
                  <InputGroup.Text
                    className={errors.shiftDate ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.shiftDate && touched.shiftDate ? (
                <Alert variant="danger">{errors.shiftDate}</Alert>
              ) : null}
            </Form.Group>

            <hr />

            <h4>New Check-In Co-ordinates</h4>

            <Form.Group className="mb-3 text-start" controlId="lattitude">
              <Form.Label>Lattitude</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineLocationOn />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="40.7777"
                  name="lattitude"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.lattitude}
                  className={
                    errors.lattitude && touched.lattitude ? "input-error" : null
                  }
                />
                {errors.lattitude && touched.lattitude && (
                  <InputGroup.Text
                    className={errors.lattitude ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.lattitude && touched.lattitude ? (
                <Alert variant="danger">{errors.lattitude}</Alert>
              ) : null}
            </Form.Group>

            {/* If we use Co-ordinates   */}
            <Form.Group className="mb-3 text-start" controlId="longitude">
              <Form.Label>Longitude</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineLocationOn />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="-78.65555"
                  name="longitude"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.longitude}
                  className={
                    errors.longitude && touched.longitude ? "input-error" : null
                  }
                />
                {errors.longitude && touched.longitude && (
                  <InputGroup.Text
                    className={errors.longitude ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.longitude && touched.longitude ? (
                <Alert variant="danger">{errors.longitude}</Alert>
              ) : null}
            </Form.Group>

            {/* <hr /> */}

            {/* If we use address   */}
            {/* <h4>New Check-In Address</h4>

            <Form.Group className="mb-3 text-start" controlId="address">
              <Form.Label>Updated Check-In Location</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineLocationOn />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="4000 Blue Ridge Rd, Ste. 101"
                  name="address"
                   onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.address}
                  className={
                    errors.address && touched.address ? "input-error" : null
                  }
                />
                {errors.address && touched.address && (
                  <InputGroup.Text
                    className={errors.address ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.address && touched.address ? (
                <Alert variant="danger">{errors.address}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="city">
              <Form.Label>City</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineLocationOn />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Raleigh"
                  name="city"
                   onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.city}
                  className={errors.city && touched.city ? "input-error" : null}
                />
                {errors.city && touched.city && (
                  <InputGroup.Text
                    className={errors.city ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.city && touched.city ? (
                <Alert variant="danger">{errors.city}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="state">
              <Form.Label>State</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineLocationOn />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="NC"
                  name="state"
                   onChange={(e) => {
                    handleChange(e);
                    handleUserMessage({});
                  }}
                  value={values.state}
                  className={
                    errors.state && touched.state ? "input-error" : null
                  }
                />
                {errors.state && touched.state && (
                  <InputGroup.Text
                    className={errors.state ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.state && touched.state ? (
                <Alert variant="danger">{errors.state}</Alert>
              ) : null}
            </Form.Group> */}

            <SubmitBtn
              isSubmitting={isSubmitting}
              text={"Update Check-In Location"}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ChangeCheckinLocationForm;
